import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useDispatch } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString } from '../../../shared/utils/util-functions';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import { getWidget } from '../../../shared/widgets';
import { gql, useMutation } from '@apollo/client';
import FilterEvent from './filter-event';
import CreateEvent from './create-event';
import ConfirmModal from "../../../shared/hoc/modal/sendEmail"
import { useLocation, useNavigate } from 'react-router-dom';
import { stopPageLoading } from '../../../store/reducers/AppSlice';

const DELETE_EVENT = gql`mutation Mutation($deleteEventId: ID!) {
  deleteEvent(id: $deleteEventId) {
    success
    message
    errors
  }
}`

export default function Event() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    type ChildRef = {
        refetchData: (data: any) => void;
    };

    const pageMeta = useContext(PageMetaContext);

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Events");
        }
    }, [pageMeta]);

    useEffect(() => {

        const checkloader = location.pathname.includes('events');

        if (checkloader) {
            dispatch(stopPageLoading());
        }

    })


    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [filterData, setFilterData]: any = React.useState({})
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const [deleteEvent] = useMutation(DELETE_EVENT);
    const { enqueueSnackbar } = useSnackbar();

    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText ?? "",
            eventType: filterData?.eventType,
            year: filterData?.year,
        });
    };

    const handleDeleteEvent = async () => {
        const variables = {
            deleteEventId: selectedUser.id,
        }
        try {
            const { data } = await deleteEvent({
                variables: variables
            });

            if (data.deleteEvent.errors?.length > 0) {
                console.error('Error:', data.deleteEvent.errors);
                enqueueSnackbar(data.deleteEvent.errors[0], { variant: "error" });

            } else {
                console.log('Event deleted:');
                enqueueSnackbar(("Event deleted successfully"), { variant: "success" });
                setSelectedUser(null);
                handleRefetch();

            }
        } catch (error) {
            console.error('Mutation error:', error);
        }
    }


    const TableConfig = useMemo(() => {
        return [

            {
                label: "Title",
                align: "left",
                accessor: (row: any) => row.title
            },

            {
                label: "Description",
                align: "left",
                accessor: (row: any) => row.description
            },
            {
                label: "Date",
                align: "left",
                accessor: (row: any) => row.date
            },
            {
                label: "Location",
                align: "left",
                accessor: (row: any) => row.location
            },
            {
                label: "Type",
                align: "left",
                accessor: (row: any) => row.eventType
            },
            {
                label: "Action",
                align: "left",
                width: 130,
                accessor: (row: any) => {
                    return <>
                        <ActionMenu
                            button={{
                                type: "icon-button",
                                icon: <MoreVertOutlined />,
                                size: 'small',
                                color: 'primary',
                                sx: { color: 'white' }

                            }}
                            children={
                                [
                                    <MenuItem onClick={(event) => {
                                        setSelectedUser(row)
                                        setAddModalOpen(true)
                                    }}>Edit</MenuItem>,
                                    <MenuItem onClick={(event) => {
                                        setSelectedUser(row);
                                        setDeleteModalOpen(true);
                                    }}>Delete</MenuItem>,
                                ]
                            }
                        />
                    </>
                }
            }

        ];
    }, [])



    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)


    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };



    const QUERY = `query Query($search: String, $page: Int, $pageSize: Int, $eventType: String,$year: Int) {
  getAllEvents(search: $search, page: $page, pageSize: $pageSize, eventType: $eventType, year: $year) {
    success
    totalEvents
    errors
    eventDetails {
      title
      location
      eventType
      description
      date
      id
    }
  }
}
      `

    const ActionBarConfig: WidgetProps[] = [

        {
            type: "filter-menu",
            children: <FilterEvent filterData={filterData} onFilterChange={handleFilterChange} />,
            sx: {
                order: 1
            }
        },

        {
            type: "search-input",
            placeholder: "Search",
            onChange: onSearchTextChange,
            responsive: {
                xs: 12,
                sm: "auto"
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 1
                }
            }
        },
        {
            type: "button",
            label: (responsiveString({ xs: "Add", sm: "Create Event" })),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true);
                // setEditMode(false);
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 3
                }
            }
        }

    ]

    const onAddUserModalClose = (submitted?: boolean) => {
        setAddModalOpen(false);
        setSelectedUser(null);
        if (submitted) {
            handleRefetch();
        }
    }

    return (
        <div>
            <TablePageHoc actions={ActionBarConfig} columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY} params={{
                search: searchText,
                eventType: filterData?.eventType,
            }}
                sourceAccessor={(data) => data.getAllEvents.eventDetails} countAccessor={(data) => data.getAllEvents.totalEvents}

                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }}
                onRowClick={(row) => { navigate(`/admin/events/${row.id}`) }}
                detailPointer={true}
            />
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Event" : "Create Event",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        size: 'md',
                        children: <CreateEvent users={selectedUser} onSave={() => onAddUserModalClose(true)} onClose={onAddUserModalClose} />,
                    })
            }
            <ConfirmModal modalOpen={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={handleDeleteEvent} title={('Are you sure?')} children={('Do you want to delete the event?')} />
        </div>
    )

}
