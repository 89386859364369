import React, { useEffect, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Radio, RadioGroup } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import validator from 'validator';
import { useSnackbar } from 'notistack';


const UPDATE_USER_QUERY = gql`mutation Mutation($updateOneVisitorId: ID!, $name: String, $email: String, $contactNumber: String, $memberType: String, $description: String, $isTransportRequired: String) {
    updateOneVisitor(id: $updateOneVisitorId, name: $name, email: $email, contactNumber: $contactNumber, memberType: $memberType, description: $description, isTransportRequired: $isTransportRequired) {
      success
      errors
      visitor {
        name
        contactNumber
      }
    }
  }`



const CREATE_USER_QUERY = gql`mutation RegisterEvent($name: String!, $contactNumber: String!, $email: String!, $memberType: String!, $isTransportRequired: String, $description: String) {
  registerEvent(name: $name, contactNumber: $contactNumber, email: $email, memberType: $memberType, isTransportRequired: $isTransportRequired,description: $description) {
    success
    message
    errors
  }
}`



type ChildRef = {
    refetchData: (data: any) => void;
};



export default function AddVisitor({ users, onSave, onClose, editMode }: any) {

    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const [createUser] = useMutation(CREATE_USER_QUERY);
    const [updateUser] = useMutation(UPDATE_USER_QUERY);
    const [checked, setChecked] = React.useState(false);
    const [external, setExternal] = useState(false);
    const [memberType, setMemberType] = useState(null)


    const onCloseUser = () => {
        onClose();
    }


    useEffect(() => {
        if (users?.isTransportRequired === 'Yes') {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [])



    const Roles = [
        {
            value: 'Designer-Internal',
            label: 'Designer',
        },
        {
            value: 'Non Designer-Internal',
            label: 'Non Designer',
        },
        {
            value: 'Student-Internal',
            label: 'Student',
        },
        {
            value: 'Other-Internal',
            label: 'Other',
        },

    ];


    const handleSubmit = async (user: any, callback: Function) => {
    
        if (users) {
            const variables = {
                updateOneVisitorId: users.Visitor.id,
                name: user.name,
                contactNumber: user.contactNumber,
                email: user.email,
                memberType: memberType ? memberType : user.memberType,
                description: user.description,
                isTransportRequired: checked ? 'Yes' : 'No',

            }
            try {
                const { data } = await updateUser({
                    variables: variables
                });

                if (data.updateOneVisitor.errors?.length > 0) {
                    console.error('Error:', data.updateOneVisitor.errors);
                    enqueueSnackbar(data.updateOneVisitor.errors[0], { variant: "error" });
                    callback();
                } else {
                    console.log('visitor details updated:', data.updateOneVisitor.visitor);
                    enqueueSnackbar(("Updated successfully"), { variant: "success" });
                    onSave()
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }

        } else {
            const variables = {
                name: user.name,
                contactNumber: user.contactNumber,
                email: user.email,
                memberType: memberType ? memberType : user.memberType,
                description: user.description,
                isTransportRequired: checked ? 'Yes' : 'No',
                status: memberType === 'BBS' ? 'Sent Ticket' : 'Sent QR'
            }
            try {
                const { data } = await createUser({
                    variables: variables
                });

                if (data.registerEvent.errors?.length > 0) {
                    console.error('Error:', data.registerEvent.errors);
                    enqueueSnackbar(data.registerEvent.errors[0], { variant: "error" });
                    callback();
                } else {
                
                    enqueueSnackbar(("Registered successfully"), { variant: "success" });
                    onSave()
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }
        }

    };



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleChangeMember = (event: any) => {
        if (event.target.value === 'External') {
            setExternal(true)
        } else {
            setExternal(false)
        }

    }



    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <Grid container justifyContent={'space-between'}>
                    <Grid xs={12} >
                        <FormField name='name' value={users?.Visitor?.name ?? ""} validator={(value: string, ...data) => {
                            if (!value) return "Name is required."
                            return null
                        }} validateOnChange={false}>
                            <TextField id="name"
                                label={("Name")}
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            />
                        </FormField>
                    </Grid>

                    <Grid xs={12} >
                        <FormField name='email' value={users?.Visitor?.email ?? ""} validator={(value: string) => {
                            if (!value) {
                                return 'Email is required.'
                            }
                            if (!validator.isEmail(value)) {
                                return 'Enter a valid email address.'
                            }
                            return null
                        }} validateOnChange={false}>
                            <TextField id="email"
                                label={("Email")}
                                autoComplete='off'
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            />
                        </FormField>
                    </Grid>
                </Grid>

                <FormField name='contactNumber' value={users?.Visitor?.contactNumber ?? ""} validator={(value: string) => {
                    if (!value) {
                        return 'Contact number is required.'
                    }

                    return null
                }} validateOnChange={false}>
                    <TextField id="email"
                        label={("Contact Number")}
                        autoComplete='off'
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>

                <FormField name='memberType' value={users?.Visitor?.memberType ?? ""}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        onChange={handleChangeMember}
                        name="radio-buttons-group"
                        sx={{ mb: 20 }}

                    >
                        <Grid container justifyContent={'space-between'}>
                            <Grid xs={12} ><FormControlLabel value="BBS" control={<Radio />} label="BBS" /></Grid>
                            <Grid xs={12} ><FormControlLabel value="Golden Guest" control={<Radio />} label="Golden Guest" /></Grid>
                            <Grid xs={12} ><FormControlLabel value="External" control={<Radio />} label="External" /></Grid>

                        </Grid>
                    </RadioGroup>
                </FormField>


                {
                    external && <Grid container justifyContent={'space-between'}>
                        <Grid xs={12} >
                            <TextField id="status"
                                label={("Select Type")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}

                                onChange={(e: any) => {
                                    setMemberType(e.target.value);
                                }}

                            >
                                {Roles.map((option: any) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {(option.label)}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </Grid>
                    </Grid>
                }

                {
                    external && <FormField name='description' value={users?.description ?? ""} validator={(value: string) => {
                        if (!value) {
                            return 'Description is required.'
                        }

                        return null
                    }} validateOnChange={false}>
                        <TextField id="email"
                            label={("Description")}
                            autoComplete='off'
                            variant="outlined"
                            multiline
                            rows={2}
                            sx={{ mb: 10 }}
                            fullWidth={true}
                        />
                    </FormField>
                }


                <FormField name='isTransportRequired' value={users?.isTransportRequired ?? ""}>
                    <FormControlLabel control={<Checkbox checked={checked}
                        onChange={handleChange} />} label="I need to transport (Pick and drop)" sx={{ 'marginBottom': '20px' }} />
                </FormField>

                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" color="secondary" onClick={onCloseUser} >{('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        {
                            users ? <Button type="submit" variant="contained" color="primary">{('Save')}</Button> :
                                <Button type="submit" variant="contained" color="primary">{('Register')}</Button>
                        }

                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}
