import React, { useMemo } from 'react'
import AdminLayoutHoc from '../../shared/hoc/admin-layout/AdminLayout.default'
import logo from '../../assets/images/designlogo.png'
import logo1 from "../../assets/images/designlogo.png";
import { GridViewOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';





export default function AdminLayout() {
  const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
  

  const ADMIN_LAYOUT_CONFIG = useMemo(() => {
    const role = decodedTokenData?.roles ?? ""
    return {
      logo: <div><img src={logo} style={{ 'marginLeft': '-10px' ,'height':'55px','width':'120px'}}></img></div>,
      logoSm: <div><img src={logo1}></img></div>,
      isOpen: false,
      menuLinks: [
        ...(
          (role === "BBS Member")? [
            {
              text: 'Regd. users',
              icon: <GridViewOutlined />,
              link: "/admin/dashboard"
            }
          ]: []
        ),
        ...(
          (role === "BBS Member" || role === "Volunteer")? [
            {
              text: 'Scan attendees',
              icon: <GridViewOutlined />,
              link: "/admin/scan"
            }
          ]: []
        ),
        ...(
          (role === "BBS Member")? [
            {
              text: 'Events',
              icon: <GridViewOutlined />,
              link: "/admin/events"
            }
          ]: []
        ),
      ]
    }
  }, [decodedTokenData])

  return (
    <AdminLayoutHoc {...ADMIN_LAYOUT_CONFIG} />
  )
}
