import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type CustomValidator = (value: string, ...data: any) => string | null;

type FieldState = {
    value: string;
    error: string | null;
    validator?: CustomValidator;
};

interface SetValueProps {
    validateOnChange: boolean;
}

type FormContextType = {
    registerField: (name: string, value: string, validator?: CustomValidator) => void;
    setValue: (name: string, value: string, validator?: CustomValidator, options?: SetValueProps) => void;
    resetForm: () => void;
    resetField: (name: string) => void;
    fields: Record<string, FieldState>;
};

const FormContext = createContext<FormContextType | undefined>(undefined);

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("FormField must be used within a FormGroup");
    }
    return context;
};

type FormGroupProps = {
    children: React.ReactNode;
    onSubmit: (values: Record<string, any>, callback: Function) => void;
    onFormInit?: (resetField: Function, resetForm: Function) => void;
};

const FormGroup: React.FC<FormGroupProps> = ({ children, onSubmit, onFormInit }) => {
    const [fields, setFields] = useState<Record<string, FieldState>>({});
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitTimeoutRef = useRef<number | null>(null);

    const registerField = (name: string, value: any, validator?: CustomValidator) => {
        setFields((prevFields) => ({ ...prevFields, [name]: { value: value ?? '', error: null, validator } }));
    }

    const setValue = (name: string, newValue: string, validator?: CustomValidator, options?: SetValueProps) => {
        setFields((prevFields: any) => {
            const field = prevFields[name] || {};
            let error = null;
            if (!options || (options && options.validateOnChange)) {
                if (field.validator || validator) {
                    error = (field.validator || validator)(newValue, fields);
                    error = t(error);
                }
            }
            return { ...prevFields, [name]: { ...field, value: newValue, error, validator: field.validator || validator } };
        });
    };

    const resetForm = () => {

        setFields((prevFields: any) => {
            Object.keys(prevFields).forEach((name) => {
                const field = prevFields[name] || {};
                let error = null;
                prevFields[name] = { ...field, value: '', error, validator: field.validator };
            });
            return { ...prevFields };
        });
    };

    const resetField = (name: string, value?:any) => {
        setFields((prevFields: any) => {
            const field = prevFields[name] || {};
            let error = null;
            
            return { ...prevFields, [name]: { ...field, value: value??'', error, validator: field.validator } };
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if(isSubmitting) return;


        if (submitTimeoutRef.current) {
            clearTimeout(submitTimeoutRef.current);
        }

        submitTimeoutRef.current = window.setTimeout(() => {
            const values: any = {};

            let allValid = true;
            // This loop will validate all fields
            for (let [name, field] of Object.entries(fields)) {
                let error = null;
                if (field.validator) {
                    error = field.validator(field.value, fields);
                    if (error) {
                        allValid = false;
                        fields[name].error = t(error);
                    }
                }
                values[name] = field.value;
            }

            setFields({ ...fields });
            
            if (allValid) {
                onSubmit(values, () => {
                    setIsSubmitting(false);
                });
            }
    
        }, 300);

    };

    useEffect(() => {
        // Call the onFormInit prop when the component mounts
        if (onFormInit) {
            onFormInit(resetField, resetForm);
        }
    }, []);
    

    return (
        <FormContext.Provider value={{ setValue, fields, registerField, resetForm, resetField }}>
            <form onSubmit={handleSubmit}>{children}</form>
        </FormContext.Provider>
    );
};

type FormFieldProps = {
    name: string;
    value?: any;
    initialValue?: string;
    children: React.ReactElement;
    validator?: CustomValidator;
    validateOnChange?: boolean;
};

const FormField: React.FC<FormFieldProps> = ({ name, children, validator, value, validateOnChange, initialValue }) => {
    const { registerField, setValue, fields } = useFormContext();
    const fieldState = fields[name] || { value: value ?? initialValue ?? '', error: null };

    useEffect(() => {
        registerField(name, value ?? initialValue ?? '', validator);
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setValue) {
            setValue(name, e.target.value, validator, { validateOnChange: validateOnChange ?? false });
        }
    };

    return React.cloneElement(children, {
        name,
        value: fieldState.value,
        onChange: (e: any) => {
            handleChange(e);
            if (children.props.onChange) {
                children.props.onChange(e);
            }
        },
        error: !!fieldState.error,
        helperText: fieldState.error
    });
};

export { FormField, FormGroup };
