import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import { ModalProps, ModalPropsBase } from '../../types/widget-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import succeslogo from '../../../assets/images/success-logo.png';
import failurelogo from "../../../assets/images/fail-logo.png";


const CustomDialog = (props:any) => {
    return <Dialog 
    {...props}
    className={
        clsx({
            'sizeSmall': props.size == 'sm',
            'sizeLarge': props.size == 'lg',
            'sizeMedium': props.size == 'md'
        })
    }
    />
}

export interface ConfirmModalProps extends ModalPropsBase {
    onConfirm?: () => void;
    statusType: boolean;
    visitor:any;
}



function StatusModal({  modalOpen, onClose,statusType,visitor }: ConfirmModalProps) {


    const onCloseClick = () => {
        onClose?.()
    }




    return (
        <>
            <CustomDialog
                open={modalOpen ?? false}
                onClose={() => onCloseClick()}
                size='sm'
                sx={{ border: 0 }}

        
            >
            
                <DialogContent sx={{'textAlign':'center'}}>
                    {statusType?<img src={succeslogo} alt="" />:<img src={failurelogo} alt="" />}
                
                <DialogContentText>
                    {
                        statusType ? "Success" :"Failed"
                    }
                </DialogContentText>

                    {
                        statusType ? (
                            <div>
                                <DialogContentText sx={{'marginTop':'10px','fontSize':'20px','textTransform':'uppercase'}} >{visitor?.VerifyMemberId?.eventDetails.Visitor.name}</DialogContentText>
                                <DialogContentText sx={{'marginTop':'10px'}}>{visitor?.VerifyMemberId?.eventDetails.Visitor.memberId}</DialogContentText>
                            </div>
                        ):(
                            <DialogContentText sx={{'marginTop':'10px'}}>Not Registered</DialogContentText>
                        )
                    }
              
                </DialogContent>

            
                <DialogActions>
                
                <Button autoFocus onClick={onCloseClick} variant='contained' sx={{'margin':'auto'}} >
                    {('Done')}
                </Button>
             
                </DialogActions>
            </CustomDialog>
        </>
    )
}

export default StatusModal