import React, { useContext, useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from "../../../assets/images/designlogo.png";
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import TextField from '../../../shared/widgets/text-field/TextField';
import { Button } from '@mui/material';
import styles from "./QrScan.module.scss";
import qrlogo from "../../../assets/images/bx_qr.png";
import QrScanner from '../../../shared/widgets/qrcodescanner/QRCodeScanner';
import { gql, useMutation, useQuery } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import StatusModal from '../../../shared/hoc/modal/status-modal';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useLocation } from 'react-router-dom';



const QUERY = gql`query VerifyMemberId($eventId: Int, $memberId: Int) {
  VerifyMemberId(eventId: $eventId, memberId: $memberId) {
    errors
    success
    eventDetails {
      status
      slno
      id
      Visitor {
        id
        name
        memberId
      }
      Event {
        id
      }
    }
  }
}
      `

const UPDATE_VISITOR_STATUS = gql`mutation Mutation($status: String!, $eventId: Int!, $visitorId: Int!) {
        updateStatus(status: $status, eventId: $eventId, visitorId: $visitorId) {
          success
          status
          errors
        }
      }`



function QrScan() {
    const [scannedData, setScannedData] = useState<string | null>(null);
    const [showScanner, setShowScanner] = useState(false);
    const { refetch: visitorsRefetch } = useQuery(QUERY, { skip: true });
    const [updateStatus] = useMutation(UPDATE_VISITOR_STATUS);
    const [statusModaal, setStatusModal] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [visitorData, setVisitorData] = useState<string | null>(null);
    const dispatch = useDispatch();
    const location = useLocation();

    const pageMeta = useContext(PageMetaContext);

    useEffect(() => {
        if (pageMeta) {
            const { setPageName } = pageMeta;
            setPageName("QR Scan");
        }
    }, [pageMeta]);

    useEffect(() => {

        const checkloader = location.pathname.includes('scan');

        if (checkloader) {
            dispatch(stopPageLoading());
        }

    })



    const handleError = (err: any) => {
        console.error(err);
        // Handle error as needed
    };

    const handleUpdateStatus = async (visitorId: any, eventId: any) => {

        
        const variables = {
            visitorId: parseInt(visitorId),
            status: 'Visited',
            eventId: parseInt(eventId)
        }
        try {
            const { data } = await updateStatus({
                variables: variables
            });

            if (data.updateStatus.errors?.length > 0) {
                console.error('Error:', data.updateStatus.errors);
            } else {
                console.log('Status updated:', data.updateStatus.user);

            }
        } catch (error) {
            console.error('Mutation error:', error);
        }


    }

    const handleScan = async (data: string | null) => {
        setScannedData(data);
        setShowScanner(false);

        // Check if the scanned data is a valid URL
        if (data) {

            try {
                const url = new URL(data);
                const path = url.pathname;
                const memberIdMatch = path.match(/\/memberId:(\d+)/);
                const eventIdMatch = path.match(/\/eventId:(\d+)/);

                if (memberIdMatch && memberIdMatch[1] && eventIdMatch && eventIdMatch[1]) {
                    const memberIdValue = memberIdMatch[1];
                    const eventIdValue = eventIdMatch[1];
                    const { data } = await visitorsRefetch({
                        memberId: parseInt(memberIdValue),
                        eventId: parseInt(eventIdValue),
                    });

                    if (!data.VerifyMemberId.success) {
                        console.error('Error:', data.VerifyMemberId.errors);

                        setStatusModal(true)
                        setStatus(false)

                    } else {
                        
                        handleUpdateStatus(data.VerifyMemberId?.eventDetails.Visitor.id, data.VerifyMemberId?.eventDetails.Event.id)
                        setVisitorData(data)
                        setStatusModal(true)
                        setStatus(true)
                    }
                } else {
                    setStatusModal(true)
                    setStatus(false)
                }


            } catch (error) {
                console.log('Invalid URL:', error);

            }


        }
    };

    const handleScanWrapperClick = () => {
        setShowScanner(true);
    };

    const handleScanner = () => {
        setShowScanner(false);
    }



    const handleSubmit = async (memberId: any) => {
    
        const { data } = await visitorsRefetch({
            memberId: parseInt(memberId.MemberId),
        });

        if (!data.VerifyMemberId.success) {
            console.error('Error:', data.VerifyMemberId.errors);
            enqueueSnackbar('Not Registered', { variant: "error" });
            setStatusModal(true)
            setStatus(false)

        } else {
            
            enqueueSnackbar(("Registered successfully"), { variant: "success" });
            setVisitorData(data)
            handleUpdateStatus(data.VerifyMemberId?.eventDetails.Visitor.id, data.VerifyMemberId?.eventDetails.Event.id)
            setStatusModal(true)
            setStatus(true)
        }
    }



    return (
        <div className={styles.wrapper}>

            <div className={styles.header}>
                {
                    showScanner && (
                        <div onClick={handleScanner} className={styles.backicon}>  <ArrowBackIcon></ArrowBackIcon>
                            <h3 >Back</h3></div>

                    )
                }

            </div>
            <div className={styles.body}>
                <div className={styles.card}>
                    <img src={logo} />
                    {showScanner && <div style={{ 'alignSelf': 'center' }}><QrScanner onScan={handleScan} onError={handleError} /></div>}
                    <div style={{ display: showScanner ? 'none' : 'block' }}>
                        <div className={styles.scanwrapper} onClick={handleScanWrapperClick}>

                            {!showScanner && (
                                <>
                                    <img src={qrlogo} alt="" />
                                    <h3>Scan Ticket</h3>
                                </>
                            )}
                        </div>
                    </div>

                    {
                        showScanner && <p className={styles.qrcodetext}>QR Scanner</p>
                    }
                    {
                        !showScanner && (
                            <div>

                                <div style={{ 'display': 'flex', 'justifyContent': 'center' }}><h3 className={styles.division}>OR</h3></div>
                                <FormGroup onSubmit={handleSubmit}>
                                    <FormField name='MemberId' value={""}>
                                        <TextField id="MemberId"
                                            label={("Member Id")}
                                            variant="standard"
                                            sx={{ mb: 10 }}
                                            fullWidth={true}
                                        />
                                    </FormField>
                                    <div style={{ 'textAlign': 'center' }}><Button type="submit" variant="contained" color="primary">{('Verify')}</Button></div>

                                </FormGroup>
                            </div>
                        )
                    }

                </div>
            </div>
            <StatusModal modalOpen={statusModaal} onClose={() => setStatusModal(false)} statusType={status} visitor={visitorData} />
        </div>
    )
}

export default QrScan