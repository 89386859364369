import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useDispatch } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import FilterEventUser from './filter-event-user';
import { responsiveString } from '../../../shared/utils/util-functions';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { MenuItem } from '@mui/material';
import { getWidget } from '../../../shared/widgets';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useCookies } from 'react-cookie';
import SendEmail from '../../../shared/hoc/modal/sendEmail';
import ConfirmDownload from "../../../shared/hoc/modal/sendEmail"
import AddVisitor from './add-visitor-form';
import EventBadge from '../../../shared/widgets/badge/badge';


const UPDATE_VISITOR_STATUS = gql`mutation Mutation($status: String!, $eventId: Int!, $visitorId: Int!) {
  updateStatus(status: $status, eventId: $eventId, visitorId: $visitorId) {
    success
    status
    errors
  }
}`

const SEND_EMAIL_QUERY = gql`mutation TriggerMail($eventId: Int) {
  TriggerMail(eventId: $eventId) {
    success
    status
  }
}`


const GET_EVENT_DETAILS = gql`query Query($eventId: ID!) {
  getEventById(id: $eventId) {
    success
    event {
      title
    }
    errors
  }
}`

export default function EventUsers({ onUpdateTotalVisitors, status, eventId }: any) {

    const dispatch = useDispatch();

    type ChildRef = {
        refetchData: (data: any) => void;
    };

    const pageMeta = useContext(PageMetaContext);
    const [updateStatus] = useMutation(UPDATE_VISITOR_STATUS);
    const [sendMail] = useMutation(SEND_EMAIL_QUERY);
    const { refetch: eventRefetch } = useQuery(GET_EVENT_DETAILS, { skip: true });
    const [event, setEvent]:any = useState(null);
    console.log(event)

    useEffect(() => {
        getEventDetails()
    }, [eventId])


    const getEventDetails = async () => {

        const { data } = await eventRefetch({
            eventId: parseInt(eventId)
        });
        setEvent(data?.getEventById?.event?.title)
    }


    useEffect(() => {
        if (pageMeta && event) {
            const { setPageName } = pageMeta;
            setPageName(event);
        }
    }, [pageMeta,event]);


    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [sendEmailConfirmModal, setSendEmailConfirmModal] = React.useState(false);
    const [downloadConfirmModal, setDownloadConfirmModal] = React.useState(false);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [editMode, setEditMode]: any = React.useState(false);

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [filterData, setFilterData]: any = React.useState({});

    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText ?? "",
            memberType: filterData?.memberType,
            eventId: parseInt(eventId),
            isTransportRequired: filterData?.isTransportRequired,
            status: status ? status : null
        });
    };


    const handleSendQRCode = async (row: any) => {
        const variables = {
            visitorId: parseInt(row?.Visitor?.id),
            status: 'Sent QR',
            eventId: parseInt(row?.Event?.id)
        }
        try {
            const { data } = await updateStatus({
                variables: variables
            });

            if (data.updateStatus.errors?.length > 0) {
                console.error('Error:', data.updateStatus.errors);
                enqueueSnackbar(data.updateStatus.errors[0], { variant: "error" });

            } else {

                enqueueSnackbar(("Status updated successfully"), { variant: "success" });
                handleRefetch();
                onUpdateTotalVisitors();
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }
    }

    const handleSendTicketStatus = async (row: any) => {
        const variables = {
            visitorId: parseInt(row?.Visitor?.id),
            status: 'Sent Ticket',
            eventId: parseInt(row?.Event?.id)
        }
        try {
            const { data } = await updateStatus({
                variables: variables
            });

            if (data.updateStatus.errors?.length > 0) {
                console.error('Error:', data.updateStatus.errors);
                enqueueSnackbar(data.updateStatus.errors[0], { variant: "error" });

            } else {

                enqueueSnackbar(("Status updated successfully"), { variant: "success" });
                handleRefetch();
                onUpdateTotalVisitors();

            }
        } catch (error) {
            console.error('Mutation error:', error);
        }
    }


    const handleVisitedStatus = async (row: any) => {
        const variables = {
            visitorId: parseInt(row?.Visitor?.id),
            status: 'Visited',
            eventId: parseInt(row?.Event?.id)
        }
        try {
            const { data } = await updateStatus({
                variables: variables
            });

            if (data.updateStatus.errors?.length > 0) {
                console.error('Error:', data.updateStatus.errors);
                enqueueSnackbar(data.updateStatus.errors[0], { variant: "error" });

            } else {

                enqueueSnackbar(("Status updated successfully"), { variant: "success" });
                handleRefetch();
                onUpdateTotalVisitors();
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }
    }


    const handleRejectStatus = async (row: any) => {

        const variables = {
            visitorId: parseInt(row?.Visitor?.id),
            status: 'Rejected',
            eventId: parseInt(row?.Event?.id)
        }
        try {
            const { data } = await updateStatus({
                variables: variables
            });

            if (data.updateStatus.errors?.length > 0) {
                console.error('Error:', data.updateStatus.errors);
                enqueueSnackbar(data.updateStatus.errors[0], { variant: "error" });

            } else {

                enqueueSnackbar(("Status updated successfully"), { variant: "success" });
                handleRefetch();
                onUpdateTotalVisitors();
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }
    }


    const TableConfig = useMemo(() => {
        return [
            {
                label: "",
                align: "left",
                accessor: (row: any) => <EventBadge eventCount={row?.eventsAttended} eventNames={row?.eventNames} />
            },

            {
                label: "Id",
                align: "left",
                accessor: (row: any) => row?.Visitor?.memberId
            },
            {
                label: "Name",
                align: "left",
                accessor: (row: any) => row?.Visitor?.name
            },

            {
                label: "Phone number",
                align: "left",
                accessor: (row: any) => row?.Visitor?.contactNumber
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row?.Visitor?.email
            },
            {
                label: "Member Type",
                align: "left",
                accessor: (row: any) => row?.Visitor?.memberType
            },
            {
                label: "Description",
                align: "left",
                accessor: (row: any) => row?.Visitor?.description
            },
            {
                label: "Status",
                align: "left",
                accessor: (row: any) => row?.status
            },
            ...(status != null && status !== "Visited" ? ([
                {
                    label: "Action",
                    align: "left",
                    width: 130,
                    accessor: (row: any) => {
                        return <>
                            <ActionMenu
                                button={{
                                    type: "icon-button",
                                    icon: <MoreVertOutlined />,
                                    size: 'small',
                                    color: 'primary',
                                    sx: { color: 'white' }
    
                                }}
                                children={
                                    [ status !== "Rejected" && status !== "Sent QR" && status !== "Sent Ticket" && <MenuItem onClick={(event) => {
                                        setSelectedUser(row);
                                        handleSendQRCode(row);
                                    }}>Send QR</MenuItem>,
                                    status !== "Rejected" && status !== "Sent Ticket" &&
                                    <MenuItem onClick={(event) => {
                                        setSelectedUser(row);
                                        handleSendTicketStatus(row);
                                    }}>Send Ticket</MenuItem>,
                                    status !== "Rejected" && status !== "Sent QR" && status !== "Sent Ticket" &&
                                    <MenuItem onClick={(event) => {
                                        setSelectedUser(row);
                                        handleRejectStatus(row);
                                    }}>Reject</MenuItem>,
                                    status !== "Rejected" && status !== "Sent QR" &&
                                    <MenuItem onClick={(event) => {
                                        setSelectedUser(row);
                                        handleVisitedStatus(row);
                                    }}>Visited</MenuItem>,
                                    <MenuItem onClick={(event) => {
                                        setSelectedUser(row);
                                        setAddModalOpen(true)
                                        event.stopPropagation();
                                        setEditMode(true)
                                    }}>Edit</MenuItem>,
                                    ]
                                }
                            />
                        </>
                    }
                }
            ]):[]) 
        ];
    }, [status])



    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    useUpdateEffect(() => {
        handleRefetch();

    }, [filterData, searchText], 300)


    const handleFilterChange = (filters: any) => {

        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };


    const [cookies] = useCookies(['token']);
    const token = cookies.token;

    const download = async () => {


        try {
            //   const BASE_URL = "http://localhost:6868";
            const BASE_URL = "https://api.thedezignquest.com/";
            const response = await fetch(BASE_URL + '/excelFile-download?' + new URLSearchParams({
                search: searchText ?? "",
                memberType: filterData?.memberType ?? "",
                isTransportRequired: filterData?.isTransportRequired ?? "",
                status: status ? status : null,
                eventId: eventId
            }), {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status === 200) {
                enqueueSnackbar(("Visitors list downloaded successfully"), { variant: "success" });
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const day = currentDate.getDate().toString().padStart(2, '0');
                const hours = currentDate.getHours().toString();
                const minutes = currentDate.getMinutes().toString();

                const currentDateTime = `${month}_${day}_${year}_${hours}:${minutes}`;

                link.download = `${('VisitorsList')}-${currentDateTime}.xlsx`;

                link.target = '_blank';


                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                URL.revokeObjectURL(link.href);
            }
            else if (response.status === 404) {
                enqueueSnackbar(("No Visitors were found for downloading"), { variant: "error" });

            }
            ;

        } catch (error) {
            console.log('An error occurred while downloading the player list:', error);
        }
    }

    const QUERY = `query GetEventUsers($eventId: Int, $search: String, $page: Int, $pageSize: Int, $status: String, $memberType: String, $isTransportRequired: String) {
  getEventUsers(eventId: $eventId, search: $search, page: $page, pageSize: $pageSize, status: $status, memberType: $memberType, isTransportRequired: $isTransportRequired) {
    totalEvents
    success
    eventDetails {
    status
    isTransportRequired
    eventsAttended
    eventNames
      Visitor {
        email
        id
        memberId
        memberType
        name
        slno
        status
        contactNumber
        description
      }
        Event{
        id}
    }
    errors
  }
}
      `

    const sendEmail = async () => {
        try {
            const variables = {
                eventId: parseInt(eventId)
            }
            const { data } = await sendMail({ variables });

            if (data.TriggerMail.errors?.length > 0) {
                console.error('Error:', data.TriggerMail.errors);
                enqueueSnackbar(data.TriggerMail.errors[0], { variant: "error" });

            } else {
                console.log('Status updated:', data.TriggerMail);
                enqueueSnackbar(("Email sent successfully"), { variant: "success" });
            }

        } catch (error) {
            console.error('Mutation error:', error);
        }
    }


    const ActionBarConfig = useMemo(() => {
        return [

            {
                type: "filter-menu",
                children: <FilterEventUser filterData={filterData} onFilterChange={handleFilterChange} />,
                sx: {
                    order: 1
                }
            },

            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 2,
                        sm: 1
                    }
                }
            },

            ...((!status) ? (
                [
                    (
                        {
                            type: "button",
                            label: (responsiveString({ xs: "Add", sm: "Add Member" })),
                            startIcon: <Add />,
                            onClick: () => {
                                setAddModalOpen(true);
                                setEditMode(false);
                            },
                            sx: {
                                order: {
                                    xs: 2,
                                    sm: 3
                                }
                            }
                        }
                    )
                ]
            ) : ([])),

            ...((!status) ? (
                [
                    (
                        {
                            type: "button",
                            label: "Send Email",
                            // startIcon: <DownloadIcon />,
                            variant: "contained",
                            color: 'secondary',
                            onClick: () => setSendEmailConfirmModal(true),
                            sx: {
                                order: {
                                    xs: 3,
                                    sm: 4
                                }
                            }
                        }
                    )
                ]
            ) : ([])),
            {
                type: "button",
                label: "Download",
                startIcon: <DownloadIcon />,
                variant: "contained",
                color: 'secondary',
                onClick: () => setDownloadConfirmModal(true),
                sx: {
                    order: {
                        xs: 3,
                        sm: 4
                    }
                }
            }
        ]
    }, [filterData])

    const onAddUserModalClose = (submitted?: boolean) => {
        setAddModalOpen(false);
        setSelectedUser(null);
        onUpdateTotalVisitors();
        if (submitted) {
            handleRefetch();
        }
    }

    return (
        <div>
            <TablePageHoc actions={ActionBarConfig as WidgetProps[]} columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY} params={{
                search: searchText,
                memberType: filterData?.memberType,
                isTransportRequired: filterData?.isTransportRequired,
                eventId: parseInt(eventId),
                status: status ? status : null
            }}
                sourceAccessor={(data) => data.getEventUsers.eventDetails} countAccessor={(data) => data.getEventUsers.totalEvents}

                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }}


            />
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Registeration" : "Registeration form",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        size: 'md',
                        children: <AddVisitor users={selectedUser} editMode={editMode} onSave={() => onAddUserModalClose(true)} onClose={onAddUserModalClose} />,
                    })
            }
            <SendEmail modalOpen={sendEmailConfirmModal} onClose={() => setSendEmailConfirmModal(false)} onConfirm={sendEmail} title={('Are you sure?')} children={('Do you want to send email for all the visited members?')} />
            <ConfirmDownload modalOpen={downloadConfirmModal} onClose={() => setDownloadConfirmModal(false)} onConfirm={download} title={('Are you sure?')} children={('Do you want to download the visitors members list?')} />
        </div>
    )

}
