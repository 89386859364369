import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useDispatch } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import FilterUser from '../filter-user/FilterUser';
import { gql, useMutation } from '@apollo/client';
import { useCookies } from 'react-cookie';
import SendEmail from '../../../shared/hoc/modal/sendEmail';
import EventBadge from '../../../shared/widgets/badge/badge';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import { useLocation } from 'react-router-dom';

const SEND_EMAIL_QUERY = gql`mutation TriggerMail {
  TriggerMail {
    success
    status
    errors
  }
}`

export default function DashBoard({ status }: any) {

  const dispatch = useDispatch();
  const [sendMail] = useMutation(SEND_EMAIL_QUERY);
  const location = useLocation();

  type ChildRef = {
    refetchData: (data: any) => void;
  };

  const pageMeta = useContext(PageMetaContext);

  useEffect(() => {
    if (pageMeta) {
      const { setPageName, setBreadcrumbs } = pageMeta;
      setPageName("Visitors");
    }
  }, [pageMeta]);

  useEffect(() => {

    const checkloader = location.pathname.includes('dashboard');

    if (checkloader) {
        dispatch(stopPageLoading());
    }

})



  const [sendEmailConfirmModal, setSendEmailConfirmModal] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = React.useState(1);
  const childRef = useRef<ChildRef | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [filterData, setFilterData]: any = React.useState({});

  const handleRefetch = () => {
    childRef.current?.refetchData({
      search: searchText ?? "",
      memberType: filterData?.memberType,
      isTransportRequired: filterData?.isTransportRequired,
      status: status ? status : null
    });
  };

  const TableConfig = useMemo(() => {
    return [
      {
        label: "",
        align: "left",
        accessor: (row: any) => <EventBadge eventCount={row?.eventsAttended} eventNames={row?.eventNames} />
      },

      {
        label: "Id",
        align: "left",
        accessor: (row: any) => row.memberId
      },
      {
        label: "Name",
        align: "left",
        accessor: (row: any) => row.name
      },

      {
        label: "Phone number",
        align: "left",
        accessor: (row: any) => row.contactNumber
      },
      {
        label: "Email",
        align: "left",
        accessor: (row: any) => row.email
      },
      {
        label: "Member Type",
        align: "left",
        accessor: (row: any) => row.memberType
      },
      {
        label: "Description",
        align: "left",
        accessor: (row: any) => row.description
      },

    ];
  }, [])



  const onSearchTextChange = (event: any) => {
    if (searchText !== event.target.value) {
      setPage(1)
    }
    setSearchText(event.target.value)
  }

  useUpdateEffect(() => {
    handleRefetch();
  }, [filterData, searchText], 300)


  const handleFilterChange = (filters: any) => {
    if (filters.submit) {
      setPage(1)
      setFilterData(filters.filters);
    }
  };


  const [cookies] = useCookies(['token']);
  const token = cookies.token;

  const download = async () => {


    try {
      // const BASE_URL = "http://localhost:6868";
      const BASE_URL = "https://api.thedezignquest.com/";
      const response = await fetch(BASE_URL + '/excelFile-download?' + new URLSearchParams({
        search: searchText ?? "",
        memberType: filterData?.memberType ?? "",
        isTransportRequired: filterData?.isTransportRequired ?? "",
        status: filterData ? filterData.status : null,
      }), {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      if (response.status === 200) {
        enqueueSnackbar(("Visitors list downloaded successfully"), { variant: "success" });
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString();
        const minutes = currentDate.getMinutes().toString();

        const currentDateTime = `${month}_${day}_${year}_${hours}:${minutes}`;

        link.download = `${('VisitorsList')}-${currentDateTime}.xlsx`;

        link.target = '_blank';


        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(link.href);
      }
      else if (response.status === 404) {
        enqueueSnackbar(("No Visitors were found for downloading"), { variant: "error" });
      
      }
      ;

    } catch (error) {
      console.log('An error occurred while downloading the player list:', error);
    }
  }


  const QUERY = `query Query($search: String, $page: Int, $pageSize: Int, $memberType: String, $isTransportRequired: String, $status: String) {
    getAllVisitors(search: $search, page: $page, pageSize: $pageSize, memberType: $memberType, isTransportRequired: $isTransportRequired,  status: $status) {
      success
      errors
      totalVisitors
      visitorDetails {
        contactNumber
        eventsAttended
        eventNames
        description
        email
        isTransportRequired
        memberType
        id
        name
        status
        memberId
        slno
      }
    }
  }
      `

  const sendEmail = async () => {
    try {
      const { data } = await sendMail();

      if (data.TriggerMail.errors?.length > 0) {
        console.error('Error:', data.TriggerMail.errors);
        enqueueSnackbar(data.TriggerMail.errors[0], { variant: "error" });

      } else {
        
        enqueueSnackbar(("Email sent successfully"), { variant: "success" });
      }

    } catch (error) {
      console.error('Mutation error:', error);
    }
  }


  const ActionBarConfig: WidgetProps[] = [

    {
      type: "filter-menu",
      children: <FilterUser filterData={filterData} onFilterChange={handleFilterChange} />,
      sx: {
        order: 1
      }
    },

    {
      type: "search-input",
      placeholder: "Search",
      onChange: onSearchTextChange,
      responsive: {
        xs: 12,
        sm: "auto"
      },
      sx: {
        order: {
          xs: 2,
          sm: 1
        }
      }
    }
  ]



  return (
    <div>
      <TablePageHoc actions={ActionBarConfig} columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY} params={{
        search: searchText,
        memberType: filterData?.memberType,
        status: status ? status : null
      }}
        sourceAccessor={(data) => data.getAllVisitors.visitorDetails} countAccessor={(data) => data.getAllVisitors.totalVisitors}

        pagination={{
          enable: true,
          page: page,
          onChange: (page) => {
            setPage(page)
          }
        }}


      />
      <SendEmail modalOpen={sendEmailConfirmModal} onClose={() => setSendEmailConfirmModal(false)} onConfirm={sendEmail} title={('Are you sure?')} children={('Do you want to send email for all the visited members?')} />
    </div>
  )

}
