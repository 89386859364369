import React, { useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { useTranslation } from 'react-i18next'


function FilterUser({ filterData, onFilterChange, onClose }: any) {

    const { t } = useTranslation();
    const [resetForm, setResetForm] = useState<any>(null);


    const Members = [
        {
            value: 'BBS',
            label: 'BBS',
        },
        {
            value: 'Golden Guest',
            label: 'Golden Guest',
        },
        {
            value: 'Designer-Internal',
            label: 'Designer - Internal',
        },
        {
            value: 'Student-Internal',
            label: 'Student - Internal',
        },
    
        {
            value: 'Other-Internal',
            label: 'Other - Internal',
        },
        {
            value: 'Non Designer-Internal',
            label: 'Non Designer - Internal',
        },
        {
            value: 'Designer',
            label: 'Designer',
        },
        {
            value: 'Student',
            label: 'Student',
        },
        {
            value: 'Other',
            label: 'Other',
        },
        {
            value: 'Non Designer',
            label: 'Non Designer',
        },

    ];


    console.log("filterdata---",filterData)


    const handleSubmit = async (user: any, callback: Function) => {

        const filters = {
            memberType: user.memberType || null,
        };

        onFilterChange({
            submit: true,
            filters: filters,
            callback
        });

        onClose();

    };


    return (
        // <div>
        <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm(() => resetForm)}>
            <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FormField name='memberType' value={filterData?.memberType ?? ""} >
                        <TextField id="roles"
                            label={t("Member type")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                        >
                            {Members.map((option) => (
                                <MenuItem key={option.value} value={option.value} >
                                    {t(option.label)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormField>

                <Grid sx={{alignSelf:'end'}}>
                    <Button type="button" variant="text" onClick={() => {
                        resetForm();
                        // setAssociationData(null)
                        onFilterChange({
                            submit: true,
                            filters: {
                                memberType: null,
                            
                            },
                        });
                        // onClose();
                    }} color="primary">{t('Clear')}</Button>
                    <Button type="submit" variant="contained" color="primary">{t('Filter')}</Button>
                </Grid>
            </Box>
        </FormGroup>
        // </div>
    )
}

export default FilterUser